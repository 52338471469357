<template>
  <div>
    <NavBreadcrumb v-if="pageType === '1'"></NavBreadcrumb>
    <div class="panel-style">
      <el-form :inline="true" :model="searchForm" size="mini">
        <el-row>
          <el-form-item label="设备编号" v-if="pageType === '1'">
            <el-input v-model="searchForm.deviceNum" clearable placeholder="请输入事件编号"
                      class="sfStyle" :class="pageType == '2' ? 'ml5px' : ''"/>
          </el-form-item>
          <el-form-item label="事件分类">
            <el-select v-model="searchForm.classify" clearable placeholder="请选择事件分类"
                       class="sfStyle" :class="pageType == '2' ? 'ml5px' : ''" @change="classifyChange">
              <el-option label="环境" value="环境"></el-option>
              <el-option label="柴发控制器" value="柴发控制器"></el-option>
              <el-option label="锂电池BMS" value="锂电池BMS"></el-option>
              <el-option label="电源变换器" value="电源变换器"></el-option>
              <el-option label="监控自定义" value="监控自定义"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="事件名称">
            <el-select v-model="searchForm.names" clearable placeholder="请选择事件名称" multiple collapse-tags
                       style="width:180px;" :class="pageType == '2' ? 'ml5px' : ''" @change="eventsNameChange($event)">
              <el-option v-for="item in eventsDefineArray" :key="item.id" :label="item.name" :value="item.originalName">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="事件类型" v-if="eventsDefineTypeArray.length > 0">
            <el-select v-model="searchForm.type" clearable placeholder="请选择事件类型"
                       class="sfStyle" :class="pageType == '2' ? 'ml5px' : ''">
              <el-option v-for="item in eventsDefineTypeArray" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="组件序号" v-if="serialArray.length > 0">
            <el-select v-model="searchForm.serial" clearable placeholder="请选择组件序号"
                       class="sfStyle" :class="pageType == '2' ? 'ml5px' : ''">
              <el-option v-for="item in serialArray" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="事件码">
            <el-input v-model="searchForm.code" clearable placeholder="请输入事件码"
                      class="sfStyle" :class="pageType == '2' ? 'ml5px' : ''"/>
          </el-form-item>
          <el-form-item label="事件等级">
            <el-select v-model="searchForm.level" clearable placeholder="请选择事件等级"
                       class="sfStyle" :class="pageType == '2' ? 'ml5px' : ''">
              <el-option label="1级" value="1"></el-option>
              <el-option label="2级" value="2"></el-option>
              <el-option label="3级" value="3" style="color:red"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称模糊查询">
            <el-input v-model="searchForm.nameLike" clearable placeholder="请输入事件名称"
                      class="sfStyle" :class="pageType == '2' ? 'ml5px' : ''" @change="clearOption('nameLike')"/>
          </el-form-item>
          <el-form-item label="上报时间">
            <el-date-picker
                :class="pageType == '2' ? 'ml5px' : ''"
                value-format="yyyy-MM-dd"
                v-model="searchForm.eventTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getTableData" :loading="listLoading">查询</el-button>
            <el-button type="primary" @click="downloadData" :loading="exportLoading">下载</el-button>
          </el-form-item>
        </el-row>
      </el-form>
      <div style="height:550px;">
        <el-scrollbar wrap-style="overflow-x:hidden;" style="height:100%;">
          <el-table :data="tableData" border size="small" stripe max-height="550px"
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="listLoading">
            <el-table-column prop="stationName" label="所属电站"/>
            <el-table-column prop="deviceNum" label="设备编号"/>
            <el-table-column prop="classify" label="事件分类"/>
            <el-table-column prop="code" label="事件码"/>
            <el-table-column prop="name" label="事件名称" :formatter="eventNameFormat"/>
            <el-table-column prop="level" label="事件等级" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.level==='1'">1级</span>
                <span v-else-if="scope.row.level==='2'">2级</span>
                <span v-else-if="scope.row.level==='3'" style="color: red">3级</span>
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="事件说明" width="140px" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.remark==='' || scope.row.remark===null">--</span>
                <el-tooltip v-else effect="dark" placement="top-start">
                  <div style="margin-top: 5px" slot="content">
                    <span>{{ scope.row.remark }}</span>
                  </div>
                  <i class="el-icon-chat-dot-square" style="font-size: 15px;cursor:pointer;margin-left: 10px"/>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="reportTime" label="上报时间" width="140px" :formatter="dateFormat" align="center"/>
          </el-table>
        </el-scrollbar>
      </div>
      <div style="margin-top: 15px;text-align: right;">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="curr"
            :page-sizes="[10, 50, 100]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableTotal">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryEventsRecordList,
  queryDevicePartsInfo,
  queryEventsDefineClassify,
  exportEventsRecord
} from '../../api/station/eventsRecordApi'
import NavBreadcrumb from "../../components/NavBreadcrumb";

export default {
  name: "EventsRecord",
  components: {NavBreadcrumb},
  props: {

    // 1 代表查询所有设备的事件记录 2 代表从具体设备点进去的精确查询
    pageType: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },
      // table数据
      tableData: [],
      // 总条数
      tableTotal: 0,
      // 加载圈
      listLoading: false,
      exportLoading: false,
      curr: 1,
      size: 10,
      eventsDefineArray: [],
      eventsDefineTypeArray: [],
      serialArray: [],
      searchForm: {

        deviceWholeId: '',
        deviceNum: '',
        classify: '',
        names: [],
        type: '',
        serial: '',
        code: '',
        nameLike: '',
        level: '',
        eventTime: ''
      }
    };
  },
  methods: {

    // 查询事件定义选项内容
    queryEventsDefineOption(classify) {

      let param = {

        classify: classify
      }
      queryEventsDefineClassify(param).then((res) => {

        let array = res.data;
        let tempEventsDefineArray = [];
        for (let i = 0; i < array.length; i++) {

          let obj = array[i];
          tempEventsDefineArray.push(
              {
                id: obj.id,
                originalName: obj.name,
                name: obj.name.replace("*", ''),
                type1Name: obj.type1Name,
                type0Name: obj.type0Name,
                classify: obj.classify,
                isTop: obj.isTop,
                deviceTypeId: obj.deviceTypeId
              }
          )
        }
        this.eventsDefineArray = tempEventsDefineArray;
      }).catch(err => console.error(err));
    },
    // 根据类型查询设备组件的组号集合
    queryPartsSearialOption(classify, isTop, deviceTypeId) {

      this.searchForm.serial = '';
      this.serialArray = [];
      // 非顶级的事件要进行判断
      if (isTop === '1') {

        if (this.pageType === '1') {

          if (classify === '锂电池BMS') {

            this.serialArray = [
              {id: '1', name: '1号'},
              {id: '2', name: '2号'},
              {id: '3', name: '3号'},
              {id: '4', name: '4号'},
              {id: '5', name: '5号'},
              {id: '6', name: '6号'},
              {id: '7', name: '7号'},
              {id: '8', name: '8号'},
              {id: '9', name: '9号'},
              {id: '10', name: '10号'}
            ];
          } else if (classify === '电源变换器') {

            this.serialArray = [
              {id: '1', name: '1号'},
              {id: '2', name: '2号'},
              {id: '3', name: '3号'},
              {id: '4', name: '4号'},
              {id: '5', name: '5号'},
              {id: '6', name: '6号'}
            ];
          } else {

            this.serialArray = [];
          }
        } else {

          // 查询具体设备下组件的序号
          let params = {

            deviceWholeId: this.searchForm.deviceWholeId
          };
          queryDevicePartsInfo(params).then((res) => {

            for (let i = 0; i < res.data.length; i++) {

              let partInfo = res.data[i];
              if (deviceTypeId === partInfo.deviceTypeId) {


                this.serialArray.push({

                  id: partInfo.serialNumber,
                  name: partInfo.serialNumber + "号",
                });
              }
            }
          }).catch(err => console.error(err));
        }
      }
    },
    // 查询时间定义列表
    getTableData() {

      let eventTime = this.searchForm.eventTime;
      let startTime = '';
      let endTime = '';
      if (eventTime != '' && eventTime != null) {

        startTime = eventTime[0] + ' 00:00:00';
        endTime = eventTime[1] + ' 23:59:59';
      }
      let params = {

        deviceWholeId: this.searchForm.deviceWholeId,
        deviceNum: this.searchForm.deviceNum,
        classify: this.searchForm.classify,
        names: this.searchForm.names,
        type: this.searchForm.type,
        serial: this.searchForm.serial,
        code: this.searchForm.code,
        nameLike: this.searchForm.nameLike,
        level: this.searchForm.level,
        startTime: startTime,
        endTime: endTime,
        curr: this.curr,
        size: this.size
      };
      this.listLoading = true;
      queryEventsRecordList(params).then((res) => {

        this.tableTotal = res.data.totalNum;
        this.tableData = res.data.listResult;
        this.listLoading = false;
      }).catch(err => console.error(err));
    },
    // 处理每页显示数量
    handleSizeChange(val) {

      this.size = val;
      this.getTableData();
    },
    // 处理分页
    handleCurrentChange(val) {

      this.curr = val;
      this.getTableData();
    },
    // 格式化事件名字
    eventNameFormat(row, column, cellValue) {

      let result = '';
      if (row.type === '1') {

        result = cellValue + row.type1Name;
      } else if (row.type === '0') {

        result = cellValue + row.type0Name;
      } else {

        result = "未知事件类型";
      }
      if (result.indexOf("*") != -1) {

        result = result.replace("*", row.serial);
      }
      return result;
    },
    // 日期格式化
    dateFormat(row, column, cellValue) {

      let oDate = new Date(cellValue);
      var oYear = oDate.getFullYear(),
          oMonth = oDate.getMonth() + 1,
          oDay = oDate.getDate(),
          oHour = oDate.getHours(),
          oMin = oDate.getMinutes(),
          oSec = oDate.getSeconds();
      return oYear + '-' + this.getzf(oMonth) + '-' + this.getzf(oDay) + ' ' + this.getzf(oHour) + ':' + this.getzf(oMin) + ':' + this.getzf(oSec);
    },
    getzf(num) {

      if (parseInt(num) < 10) {

        num = '0' + num;
      }
      return num;
    },
    downloadData() {

      let eventTime = this.searchForm.eventTime;
      let startTime = '';
      let endTime = '';
      if (eventTime != '' && eventTime != null) {

        startTime = eventTime[0] + ' 00:00:00';
        endTime = eventTime[1] + ' 23:59:59';
      }
      let params = {

        deviceWholeId: this.searchForm.deviceWholeId,
        deviceNum: this.searchForm.deviceNum,
        classify: this.searchForm.classify,
        names: this.searchForm.names,
        type: this.searchForm.type,
        serial: this.searchForm.serial,
        code: this.searchForm.code,
        nameLike: this.searchForm.nameLike,
        level: this.searchForm.level,
        startTime: startTime,
        endTime: endTime
      };
      this.exportLoading = true;
      exportEventsRecord(params).then(res => {

        this.exportLoading = false;
        let blob = new Blob([res]);
        let fileName = "设备事件记录.xlsx";
        //  创建 a标签
        let elink = document.createElement('a');
        elink.download = fileName;
        // 创建下载链接
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        // 释放掉blob对象
        URL.revokeObjectURL(elink.href);
        // 下载完成移除元素
        document.body.removeChild(elink);
      }).catch((err) => {
        console.log(err)
      });
    },
    classifyChange(val) {

      this.clearOption('classify');
      this.queryEventsDefineOption(val);
    },
    eventsNameChange(val) {

      this.clearOption('name');
      if (val.length === 1) {

        for (let i = 0; i < this.eventsDefineArray.length; i++) {

          let eventsDefine = this.eventsDefineArray[i];
          if (eventsDefine.originalName === val[0]) {

            // 渲染事件类型选择框
            this.eventsDefineTypeArray = [

              {id: '1', name: eventsDefine.type1Name},
              {id: '0', name: eventsDefine.type0Name}
            ]
            // 渲染组件序号选择框
            this.queryPartsSearialOption(eventsDefine.classify, eventsDefine.isTop, eventsDefine.deviceTypeId + "");
            return;
          }
        }
      } else {
        // 渲染事件类型选择框
        this.eventsDefineTypeArray = [

          {id: '1', name: '发生'},
          {id: '0', name: '解除'}
        ]
      }
    },
    // 查询条件发生变化时，要初始化条件选择或填写框
    clearOption(type) {

      if (type === 'classify') {

        this.searchForm.names = [];
        this.searchForm.type = '';
        this.searchForm.serial = '';
        this.eventsDefineArray = [];
        this.eventsDefineTypeArray = [];
        this.serialArray = [];
        this.searchForm.nameLike = '';
      } else if (type === 'name') {

        this.searchForm.type = '';
        this.searchForm.serial = '';
        this.eventsDefineTypeArray = [];
        this.serialArray = [];
      } else if (type === 'nameLike') {

        this.searchForm.classify = '';
        this.searchForm.names = [];
        this.searchForm.type = '';
        this.searchForm.serial = '';
        this.eventsDefineArray = [];
        this.eventsDefineTypeArray = [];
        this.serialArray = [];
      }
    },
    initPageData(deviceWholeId) {

      this.searchForm.deviceWholeId = deviceWholeId;
      // 先清除查询条件的必选项
      this.clearOption("nameLike");
      this.queryEventsDefineOption();
      this.getTableData();
    }
  },
  mounted() {

    if (this.pageType === '1') {

      this.initPageData();
    }
  }
}
</script>

<style scoped>
.sfStyle {

  width: 150px;
}

.ml5px {

  margin-left: 5px;
}
</style>
<style>
</style>
